import { createGlobalStyle } from "styled-components";
import BebasNeue from "../../fonts/BebasNeue-Regular.ttf";
import OpenSans from "../../fonts/OpenSans.ttf";
import theme from "./theme";

const GlobalStyles = createGlobalStyle`

    @font-face {
    font-family: 'BebasNeueRegular';
    src: url(${BebasNeue}) format('truetype')
    }    

    @font-face {
    font-family: 'OpenSans';
    src: url(${OpenSans}) format('truetype')
    }    

    body{
        overflow-x: hidden;
        margin: 0; 
        padding: 0; 
        box-sizing: border-box; 
        background-color: ${theme.common.white};
    }



    h1 {
        margin: 20px 0px 20px 0px;
        font-weight: 400;
        font-family: 'BebasNeueRegular', sans-serif;
        font-size: 4rem;
        color: ${theme.common.white};
        @media only screen and (max-width: 500px){
            font-size: 3rem;
        }
    }
    h2 {
        margin: 0px 0px 0px 0px;
        font-family: 'BebasNeueRegular', sans-serif;
        font-size: 2.5rem;
        font-weight: 300;
        color: ${theme.common.blue};
        @media only screen and (max-width: 500px){
            font-size: 2.5rem;
        }
    }
    h3 {
        margin: 30px 0px 0px 0px;
        font-family: 'BebasNeueRegular', sans-serif;
        font-size: 2.2rem;
        font-weight: 300;
        color: ${theme.common.black};
        @media only screen and (max-width: 500px){
            font-size: 1.5rem;
        }
    }
    h4 {
        font-family: 'BebasNeueRegular', sans-serif;
        font-size: 2.2rem;
        font-weight: 300;
        color: ${theme.common.black};
        @media only screen and (max-width: 500px){
            font-size: 1.5rem;
        }
    }
    h5 {
        font-family: 'BebasNeueRegular', sans-serif;
        font-size: 1.8rem;
        font-weight: 300;
        color: ${theme.common.black};
    }
    li{
        font-family: 'OpenSans', sans-serif;
        font-size: 1.2rem;
        font-weight: 300;
        color: ${theme.common.black};
    }
    p {
        font-family: 'OpenSans', sans-serif;
        font-size: 1.2rem;
        font-weight: 300;
        color: ${theme.common.black};
    }
    button {
        font-family: 'BebasNeueRegular', sans-serif;
        font-weight: 300;
    }
    a {
        font-family: 'OpenSans', sans-serif;
        font-weight: 400;
    }
    label{
        font-family: 'BebasNeueRegular', sans-serif;
        font-size: 1.2rem;
        font-weight: 300;
        color: ${theme.common.black};
    }
    #gatsby-focus-wrapper:nth-child(2){
        display:none;
    }
`;

export default GlobalStyles;
