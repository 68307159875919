import * as React from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./globalstyles";
import theme from "./theme";

const RootWrapper = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {element}
    </ThemeProvider>
  );
};

export default RootWrapper;
